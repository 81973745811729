import { keyframes } from '@emotion/react';
import { ActionButton } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useClient } from 'urql';

import addFavouriteMutation from './graphql/addFavourite.gql';
import addFavouriteToListMutation from './graphql/addFavouriteToList.gql';
import removeFavouriteMutation from './graphql/removeFavourite.gql';
import removeFavouriteFromListMutation from './graphql/removeFavouriteFromList.gql';
import { HotelInfo } from './interfaces';
import { useUnauthenticatedRedirect } from './useUnauthenticatedRedirect';
import { isUnauthenticatedError } from './utils';
import { Mutation, PageType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { getPageTypeClient } from '@Contexts/contexts';
import { useIsHydrated } from '@Core/useIsHydrated';
import { useFavouritesStore } from '@Stores/StoreContext';

export interface SaveToFavouritesButtonProps extends ClassNameProps {
  masterId: number;
  favouritesListId?: string;
  hotelInfo: HotelInfo;
}

const getFavouritesIcon = (isInList: boolean, isUpdating: boolean) => {
  if (isUpdating) {
    return isInList ? 'Toggles/FavoriteOff' : 'Toggles/FavoriteOn';
  } else {
    return isInList ? 'Toggles/FavoriteOn' : 'Toggles/FavoriteOff';
  }
};

const favouritesIconAnimation = keyframes`
  0%  { transform: scale(1); }
  14% { transform: scale(1.1); }
  28% { transform: scale(1); }
  42% { transform: scale(1.1); }
  70% { transform: scale(1); }
  100% { transform: scale(1); }
`;

/**
 * On SSR we render a semi-transparent heart icon, which then on the client becomes
 * an empty or a full heart icon based on whether the user has saved the hotel already.
 * We need to do this as the favourites data is client-side only.
 */

export const SaveToFavouritesButton: React.FC<SaveToFavouritesButtonProps> = ({
  masterId,
  favouritesListId,
  hotelInfo,
  className,
}) => {
  const { t } = useTranslation();
  const urqlClient = useClient();
  const isHydrated = useIsHydrated();
  const { pathname } = useLocation();
  const redirectToLoginPage = useUnauthenticatedRedirect();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [pageType, setPageType] = useState<PageType | undefined>();
  const [
    favouritesLists,
    addFavourite,
    addHotelToFavouritesList,
    removeFavourite,
    removeHotelFromFavouritesList,
    setShowLoginModal,
  ] = useFavouritesStore((state) => [
    state.favouritesLists,
    state.addFavourite,
    state.addHotelToFavouritesList,
    state.removeFavourite,
    state.removeHotelFromFavouritesList,
    state.setShowLoginModal,
  ]);

  const isInList = Boolean(
    favouritesListId
      ? favouritesLists.find((list) => list.id === favouritesListId)?.items.includes(masterId)
      : favouritesLists.some((list) => list.items.includes(masterId)),
  );

  const favouritesIcon = getFavouritesIcon(isInList, isUpdating);

  const tooltipText = isInList
    ? t('favourites.removeFromFavourites')
    : t('favourites.addToFavourites');

  const runBounceAnimation = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 1000);
  };

  useEffect(() => {
    // Only run getPageType call client-side on load
    async function getPageType() {
      setPageType(await getPageTypeClient(pathname));
    }

    getPageType();
  }, [pathname]);

  const addFavouriteCall = useCallback(async () => {
    const { data, error } = await urqlClient
      .mutation<Mutation>(addFavouriteMutation, { masterId, source: pageType })
      .toPromise();

    const favouritesList = data?.User?.addFavouriteV2;
    if (isUnauthenticatedError(favouritesList)) {
      setIsUpdating(false);

      return setShowLoginModal(true, masterId);
    }

    if (error || !favouritesList) {
      // TODO - error notification popup here?
      setIsUpdating(false);
      throw new Error('Error adding favourite');
    }

    const transformedList = {
      id: favouritesList.id,
      title: favouritesList.name,
      search: favouritesList.searchQuery,
      items: favouritesList.masterIds,
    };

    addFavourite(transformedList, masterId, hotelInfo);
  }, [urqlClient, masterId, pageType, addFavourite, hotelInfo, setShowLoginModal]);

  const removeFavouriteFromList = useCallback(
    async (listId: string) => {
      const { data, error } = await urqlClient
        .mutation<Mutation>(removeFavouriteFromListMutation, {
          masterId,
          listId,
          source: pageType,
        })
        .toPromise();

      const removeFavouriteResponse = data?.User.removeFavouriteFromListV2;
      if (error || !removeFavouriteResponse) {
        // TODO consider showing error notification here instead of throw
        setIsUpdating(false);
        throw new Error('Error removing favourite');
      }
      if (isUnauthenticatedError(removeFavouriteResponse)) {
        return redirectToLoginPage();
      }

      removeHotelFromFavouritesList(masterId, listId, 'favouriteslistdetails');
    },
    [urqlClient, masterId, pageType, removeHotelFromFavouritesList, redirectToLoginPage],
  );

  const undoRemoveFavouriteFromList = useCallback(
    async (listId: string) => {
      const { data, error } = await urqlClient
        .mutation<Mutation>(addFavouriteToListMutation, {
          masterId,
          listId,
          source: pageType,
        })
        .toPromise();

      const addFavouriteToListResponse = data?.User.addFavouriteToListV2;
      if (error || !addFavouriteToListResponse) {
        // TODO consider showing error notification here instead of throw
        setIsUpdating(false);
        throw new Error('Error adding favourite to list');
      }
      if (isUnauthenticatedError(addFavouriteToListResponse)) {
        return redirectToLoginPage();
      }

      addHotelToFavouritesList(masterId, listId);
    },
    [addHotelToFavouritesList, masterId, pageType, redirectToLoginPage, urqlClient],
  );

  const removeFavouriteCall = useCallback(async () => {
    const { data, error } = await urqlClient
      .mutation<Mutation>(removeFavouriteMutation, { masterId, source: pageType })
      .toPromise();

    if (isUnauthenticatedError(data?.User.removeFavouriteV2)) {
      setIsUpdating(false);

      return setShowLoginModal(true, masterId);
    }
    if (error) {
      setIsUpdating(false);
      throw new Error('Error removing favourite');
    }

    removeFavourite(masterId);
  }, [masterId, pageType, removeFavourite, setShowLoginModal, urqlClient]);

  const handleClickSaveFavouritesButton = useCallback(async () => {
    setIsUpdating(true);

    if (favouritesListId) {
      if (isInList) {
        await removeFavouriteFromList(favouritesListId!);
      } else {
        runBounceAnimation();
        await undoRemoveFavouriteFromList(favouritesListId!);
      }
    } else {
      if (isInList) {
        await removeFavouriteCall();
      } else {
        runBounceAnimation();
        await addFavouriteCall();
      }
    }

    setIsUpdating(false);
  }, [
    favouritesListId,
    isInList,
    removeFavouriteFromList,
    undoRemoveFavouriteFromList,
    removeFavouriteCall,
    addFavouriteCall,
  ]);

  return (
    <ActionButton
      data-id="search-favourites-button"
      type="Icon"
      ariaLabel={tooltipText}
      icon={!isHydrated ? 'Toggles/FavoriteOff' : favouritesIcon}
      className={className}
      disabled={isUpdating}
      onClick={(e) => {
        // Needed for panda overlay on image (can remove if we go 100% fancy-panda)
        e.stopPropagation();

        if (!isHydrated || isUpdating) {
          return;
        }

        handleClickSaveFavouritesButton();
      }}
      sx={{
        alignSelf: 'start',
        animation: `${isAnimating ? `${favouritesIconAnimation} 1s ease-in-out` : 'none'}`,
        ...(!isHydrated && {
          opacity: 0.2,
        }),
        backgroundColor: 'backgroundWhite',
        ':hover': {
          backgroundColor: 'backgroundLightsubtle',
        },
      }}
    />
  );
};
