import { useLayoutEffect } from '@loveholidays/design-system';
import { useState } from 'react';

export const useIsHydrated = () => {
  const [isHydrated, setIsHydrated] = useState(false);

  useLayoutEffect(() => {
    if (!isHydrated) {
      setIsHydrated(true);
    }
    // TODO: check this
  }, [isHydrated]);

  return isHydrated;
};
